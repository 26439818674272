import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import { Img } from '@tueri/react'
import Helmet from 'react-helmet'

const meta = {
  title: 'Progressive loading images',
  image: '274877906967',
  path: '/features/progressive-loading-images/',
  description: 'Progressively load images with low-res image placeholders',
  keywords: 'progressive images, progressive loading of images, image placeholders,  low-quality image placeholders'
}

const ImageOptimization = ({ data }) => (
    <Layout blog>
          <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: 'Tueri - ' + meta.title },
              { property: 'twitter:title', content: 'Tueri - ' + meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
          </Helmet>

        <Hero
            large
            title={ meta.title }
            subTitle={ meta.description }
        />

        <div style={{ marginBottom: '6rem' }}>
       	<Img src={ meta.image } alt='Lazy-loaded images' />
         </div>

        <p>
          Progressive loading images is a technique we use to further increase the perceived speed your users experience.
        </p>

        <p>
          When your image is requested, Tueri generates two images: the final image in the exact size your user needs for their device and a placeholder image at 1/10th size.
        </p>
        <p>
          The 1/10th size image is loaded first, scaled up and blurred to match the same dimensions as the final image. The final image is loaded in the background and once loaded, we swap the placeholder image with your final image.
        </p>

    </Layout>
)

export default ImageOptimization